import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ShowferPlayer from "showfer-player";
import {ReactComponent as CloseIcon} from "../../assets/icons/close.svg";
import {ReactComponent as FullscreenIcon} from "../../assets/icons/fullscreen.svg";
import {ReactComponent as FullscreenExitIcon} from "../../assets/icons/fullscreen_exit.svg";
import {ReactComponent as VolumeUpIcon} from "../../assets/icons/volume_up.svg";
import {ReactComponent as VolumeOffIcon} from "../../assets/icons/volume_off.svg";
import {Transition} from "react-transition-group";
import {changeActiveChannel, getCategories, nextChannel, previousChannel} from "../../store/liveTv/liveTvAction";
import {listGuideTimes, secondsToTime} from "../../utils/time";
import {readCookie} from "../../utils/cookie";
import {ReactComponent as PlayIcon} from "../../assets/icons/play_arrow.svg";

import './style.scss';

const LiveTV = () => {
    const { activeChannel, categories } = useSelector((state) => state.liveTv);
    const [showControls, setShowControls] = useState(false);
    const [showDescription, setShowDescription] = useState(false);
    const [activeProgram, setActiveProgram] = useState(null);
    const [volume, setVolume] = useState(100);
    const [fullscreen, setFullscreen] = useState(false);
    const [showGuide, setShowGuide] = useState(false);
    const [updateGuide, setUpdateGuide] = useState(true);
    const [play, setPlay] = useState(false);
    const refChannel = useRef(activeChannel);
    const dispatch = useDispatch();
    const [disabledCookie, setDisabledCookie] = useState(false);

    const firstStart = () => {
        setPlay(true);
        setShowControls(true);
    }

    useEffect(() => {
        const acceptCookie = readCookie('airy_gdpr_accept');
        if (acceptCookie && acceptCookie === 'false') {
            setDisabledCookie(true);
        }
    }, []);

    useEffect(() => {
        refChannel.current = activeChannel;
        searchActiveProgram();
    }, [activeChannel]);

    useEffect(() => {
        const currentTime = new Date();
        const time = (((30 - (currentTime.getMinutes() % 30)) * 60) - currentTime.getSeconds()) * 1000;

        const timer = setTimeout(() => {
            setUpdateGuide(!updateGuide);
            dispatch(getCategories());
        }, time);

        return () => clearTimeout(timer);
    }, [updateGuide]);

    useEffect(() => {
        const interval = setInterval(() => {
            searchActiveProgram();
        }, 5000);

        return () => clearTimeout(interval);
    }, []);

    const toggleFullScreen = () => {
        const container = document.getElementById('live-tv');
        container.onfullscreenchange = () => {
            if (document.fullscreenElement) {
                setFullscreen( true );
            } else {
                setFullscreen( false );
            }
        }

        const fullscreenApi = container.requestFullscreen
            || container.webkitRequestFullScreen
            || container.mozRequestFullScreen
            || container.msRequestFullscreen;
        if (!document.fullscreenElement) {
            return fullscreenApi.call(container);
        }

        return document.exitFullscreen();
    }

    const toggleShowControls = () => {
        switch (true) {
            case showDescription:
                setShowDescription(false);
                break;
            case showGuide:
                setShowGuide(false);
                break;
            default:
                setShowControls(!showControls);
        }
    }

    const toggleVolume = () => {
        if(volume === 0) {
            setVolume(70);
        } else {
            setVolume(0);
        }
    }

    const hideDesc = () => {
        setShowDescription(false);
        setShowControls(true);
    }

    const hideGuide = () => {
        setShowGuide(false);
        setShowControls(true);
    }

    const viewGuide = () => {
        searchActiveProgram();
        setShowGuide(true);
        setShowControls(false);
    }

    const showDesc = () => {
        searchActiveProgram();
        setShowControls(false);
        setShowDescription(true);
    }

    const searchActiveProgram = () => {
        const currentTime = Math.floor(new Date().getTime() / 1000);
        for (let itemKey in refChannel.current.broadcasts) {
            const item = refChannel.current.broadcasts[itemKey];
            const startTime = new Date(item.stream_start_at_iso).getTime() / 1000;
            const endTime = startTime + item.stream_duration;
            if (currentTime >= startTime && currentTime < endTime) {
                setActiveProgram(item);
                break;
            }
        }
    }

    const triggerNextChannel = () => {
        dispatch(nextChannel())
    }

    const triggerPreviousChannel = () => {
        dispatch(previousChannel());
    }

    const changeVolume = (element) => {
        setVolume(Number(element.target.value));
    }

    const normalizeChannelName = (name) => {
        return name.replaceAll('_', ' ', name);
    }

    const setActiveChannel = (channel) => {
        dispatch(changeActiveChannel(channel));
    }

    const scrollToCategory = (category) => {
        document.getElementById(category).scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start'
        });
    }

    const formatDateToTime = (date) => {
        const dateString = new Date(date);

        let hours = dateString.getHours();
        let minutes = dateString.getMinutes();
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;

        return hours + ':' + minutes + ' ' + ampm;
    }

    const formatDuration = (duration) => {
        let sec_num = parseInt(duration, 10);
        let hours   = Math.floor(sec_num / 3600);
        let minutes = Math.floor((sec_num - (hours * 3600)) / 60);

        return hours ? hours + 'h ' + minutes + 'm' : minutes + 'm';
    }

    const scrollGuide = (event) => {
        let block = event.target;
        let scroll = block.scrollTop;

        block.childNodes.forEach(children => {
            if (!children.classList.contains('live-tv-guide-time')) {
                switch (true) {
                    case children.offsetTop - 10 < scroll:
                        children.style['opacity'] = 0;
                        break;
                    case children.offsetTop - 20 < scroll:
                        children.style['opacity'] = 0.2;
                        break;
                    case children.offsetTop - 30 < scroll:
                        children.style['opacity'] = 0.5;
                        break;
                    case children.offsetTop - 40 < scroll:
                        children.style['opacity'] = 0.7;
                        break;
                    default:
                        children.style['opacity'] = 1;
                }
            }
        });
    }

    return (
        <div id="live-tv" className={'live-tv'}>
            <div className={'live-tv-player'}>
                {
                    activeChannel && activeChannel.source_url && play &&
                    <ShowferPlayer
                        type={ 'hls' }
                        url={ activeChannel.source_url }
                        controls={ true }
                        volume={ volume }
                        muted={ false }
                        disabledCookie={ disabledCookie }
                        fullscreenFunc={toggleFullScreen}
                        fullscreen={ fullscreen }
                        controlFunc={toggleShowControls}
                        volumeFunc={toggleVolume}
                    />
                }
            </div>
            {
                !play &&
                <div className={'live-tv-info-player-button'}>
                    <div className={'live-tv-info-player-button-play'}>
                        <PlayIcon onClick={firstStart}/>
                    </div>
                </div>
            }

            <Transition key={'transition-controls'} in={!showControls} timeout={300}>
                {(state) => (
                    <div className={`live-tv-control live-tv-control-${state}`}>
                        <div className={'live-tv-control-player'}>
                        <div className={'live-tv-control-player-volume'}>
                                <input
                                    type={'range'}
                                    min={ 0 }
                                    max={ 100 }
                                    value={ volume }
                                    onChange={ changeVolume }
                                />
                                { volume === 0 && <VolumeOffIcon style={{ cursor: 'pointer' }} onClick={ () => setVolume(70) } /> }
                                { volume !== 0 && <VolumeUpIcon style={{ cursor: 'pointer' }} onClick={ () => setVolume(0) } /> }
                            </div>
                            <div>
                                <button className={'live-tv-control-player-fullscreen'}>
                                    { fullscreen && <FullscreenExitIcon onClick={ toggleFullScreen } /> }
                                    { !fullscreen && <FullscreenIcon onClick={ toggleFullScreen } /> }
                                </button>
                            </div>
                        </div>
                        <div className={'live-tv-control-channel'}>
                            <button className={'button text-button'} onClick={ viewGuide }>
                                Channel Guide
                            </button>
                            <button className={'button text-button'} onClick={ triggerNextChannel }>
                                Next Channel
                            </button>
                        </div>
                        <div className={'live-tv-control-channel'}>
                            <button className={'button text-button'} onClick={ showDesc }>
                                Channel Info
                            </button>
                            <button className={'button icon-button'} onClick={ toggleShowControls }>
                                <CloseIcon />
                            </button>
                            <button className={'button text-button'} onClick={ triggerPreviousChannel }>
                                Previous Channel
                            </button>
                        </div>
                    </div>
                )}
            </Transition>
            <Transition key={ 'transition-desc' } in={ !showDescription } timeout={ 300 }>
                {(state) => (
                    <div className={`live-tv-description live-tv-description-${ state }`}>
                        <CloseIcon className={ 'close-icon' } onClick={ hideDesc } />
                        <p>
                            { activeProgram && secondsToTime(activeProgram.stream_duration) }
                        </p>
                        <p>
                            { activeProgram && activeProgram.title }
                        </p>
                        <p style={{ paddingBottom: '30px' }}>
                            { activeProgram && activeProgram.description }
                        </p>
                    </div>
                )}
            </Transition>
            <Transition key={ 'transition-guide' } in={ !showGuide } timeout={ 300 }>
                {(state) => (
                    <div className={`live-tv-guide live-tv-guide-${ state }`}>
                        <div className={'live-tv-guide-close'}>
                            <CloseIcon onClick={ hideGuide } />
                        </div>
                        <div id={'live-tv-guide'} className={'live-tv-guide-container'} onScroll={(event) => scrollGuide(event)}>
                            <div className={'live-tv-guide-time'}>
                                <div className={'live-tv-guide-time-categories'}>
                                    <select onChange={ (event) => scrollToCategory(event.target.value) }>
                                        { categories.map(category => (
                                            <option key={ category.name } value={ category.name }>{ normalizeChannelName(category.name) }</option>
                                        )) }
                                    </select>
                                </div>
                                { listGuideTimes().map(time => (
                                    <div key={ time } className={'live-tv-guide-time-item'}>
                                        { time }
                                    </div>
                                ))}
                            </div>
                            { categories.map(category => (
                                <>
                                    <div key={ category.name } className={'live-tv-guide-category'} id={ category.name }>
                                        { normalizeChannelName(category.name) }
                                    </div>
                                    { category.stream_channels.map(channel => {
                                        let forNext = 0;
                                        return (
                                            <div key={ channel.id } className={'live-tv-guide-channel'} onClick={ () => setActiveChannel(channel) }>
                                                <div className={`live-tv-guide-channel-item ${ channel.id === activeChannel.id ? 'active' : '' }`}>
                                                    { normalizeChannelName(channel.name) }
                                                </div>
                                                { channel.broadcasts.map(broadcast => {
                                                    let width = (263 * 17 + 5) / (1800 * 17) * broadcast.view_duration - 5;
                                                    if (width < 0) {
                                                        forNext = forNext + width + 5;
                                                        return '';
                                                    } else {
                                                        width = forNext + width;
                                                        forNext = 0;
                                                    }

                                                    return (
                                                        <div
                                                            key={ broadcast.id  }
                                                            style={{ flex: `0 0 ${ width }px` }}
                                                            className={`live-tv-guide-channel-program ${ broadcast.id === activeProgram?.id && broadcast.stream_start_at_iso === activeProgram?.stream_start_at_iso ? 'active' : '' }`}
                                                            title={ `${broadcast.title}
                                                            
Start At:   ${formatDateToTime(broadcast.stream_start_at_iso)}
Duration:   ${formatDuration(broadcast.stream_duration)}` }
                                                        >
                                                            <span>
                                                                { broadcast.title }
                                                            </span>
                                                        </div>
                                                    )
                                                }) }
                                            </div>
                                        )
                                    }) }
                                </>
                            )) }
                        </div>
                    </div>
                )}
            </Transition>
        </div>
    );
}

export default LiveTV;
