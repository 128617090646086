import React, {useState} from "react";
import { ReactComponent as ScreenIcon } from '../../assets/icons/screen.svg';
import { ReactComponent as PlayCircleIcon } from '../../assets/icons/play_circle.svg';
import { ReactComponent as MenuIcon } from '../../assets/icons/menu.svg';
import { Transition } from 'react-transition-group';
import SubMenu from "../SubMenu";

import './style.scss';

const Menu = ({ user, setUser, active, setLoginOpen }) => {
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

    const toggleSubMenu = () => {
        setIsSubMenuOpen(!isSubMenuOpen);
    }

    return (
        <div className={'menu'}>
            <Transition in={ !isSubMenuOpen } timeout={ 300 }>
                {(state) => (
                    <SubMenu user={ user } setUser={ setUser } active={ active } state={ state } toggleSubMenu={ toggleSubMenu } setLoginOpen={ setLoginOpen }/>
                )}
            </Transition>
            <div className={'menu-hamburger'}>
                <MenuIcon className={'menu-hamburger-icon'} onClick={ toggleSubMenu } />
            </div>
            <div className={'menu-items'}>
                <a href={ '/' } className={`menu-items-button ${ active === 'live tv' ? 'active' : '' }`}>
                    <ScreenIcon />
                    Live TV
                </a>
                <a href={ '/on-demand/' } className={`menu-items-button ${ active === 'on demand' ? 'active' : '' }`}>
                    <PlayCircleIcon />
                    On Demand
                </a>
            </div>
        </div>
    );
}

export default Menu;
