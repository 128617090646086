import React, {useEffect, useState} from "react";
import Menu from "../../components/Menu";
import { Outlet } from "react-router-dom";
import LoginModal from "../../components/Modal/LoginModal";
import SignUpModal from "../../components/Modal/SignUpModal";
import securityApi from "../../securityApi";
import API_ROUTES from "../../enum/apiRoutes";
import GDPR from "../GDPR";
import {readCookie} from "../../utils/cookie";

import './style.scss';

const Root = ({ active }) => {
    const [loginOpen, setLoginOpen] = useState(false);
    const [signUpOpen, setSignUpOpen] = useState(false);
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);
    const [showGDPR, setShowGDPR] = useState(false);

    useEffect(() => {
        const acceptCookie = readCookie('airy_gdpr_accept');
        if (!acceptCookie) {
            setTimeout(() => setShowGDPR(true), 2000);
        }
    }, []);

    useEffect(() => {
        if (token) {
            const checkToken = async () => {
                try {
                    const config = {
                        headers: { Authorization: `Bearer ${ token }` }
                    };
                    const response = await securityApi.post(API_ROUTES.check, null, config);

                    setUser({
                        ...user,
                        email: response.data.response.user.email
                    })
                } catch (e) {
                    changeUser(null);
                }
            }

            checkToken().then(() => {});
        }
    }, [token]);

    useEffect(() => {
        const saveToken = readCookie('airy_user_token');
        if (saveToken) {
            setToken(saveToken);
            setUser({
                ...user,
                token: saveToken,
            })
        }
    }, []);

    const changeUser = (user) => {
        setUser(user);
        if (!user) {
            const date = new Date(0);
            date.setDate(date.getDate() + 1);
            document.cookie = "airy_user_token=; expires=" + date.toGMTString() + "; path=/"
        } else {
            const date = new Date();
            date.setDate(date.getDate() + 1);
            document.cookie = "airy_user_token=" + user.token + "; expires=" + date.toGMTString() + "; path=/";
        }
    }

    return (
        <div className={'root'}>
            {
                showGDPR &&
                <GDPR
                    close={() => setShowGDPR(false)}
                />
            }
            <Menu user={ user } setUser={ changeUser } active={ active } setLoginOpen={ setLoginOpen }/>
            <Outlet />
            { loginOpen && <LoginModal setUser={ changeUser } open={ loginOpen } setOpen={ setLoginOpen } setSignUpOpen={ setSignUpOpen } /> }
            { signUpOpen && <SignUpModal setUser={ changeUser } open={ signUpOpen } setOpen={ setSignUpOpen } setLoginOpen={ setLoginOpen }/> }
        </div>
    );
}

export default Root;