import { configureStore } from '@reduxjs/toolkit'
import { liveTvReducer } from "./liveTv/liveTvReducer";
import { onDemandReducer } from "./onDemand/onDemandReducer";

export default configureStore({
    reducer: {
        liveTv: liveTvReducer,
        onDemand: onDemandReducer,
    }
});
