import { createBrowserRouter } from "react-router-dom";
import React from "react";
import LiveTV from "../pages/LiveTV";
import { channelsLoader } from "./channelsLoader";
import Root from "../pages/Root";
import OnDemand from "../pages/OnDemand";
import {contentsLoader} from "./contentsLoader";
import TermsOfUse from "../pages/TermsOfUse";
import CookiePolicy from "../pages/CookiePolicy";

const router = createBrowserRouter([
    {
        element: <Root active={ 'on demand' }/>,
        loader: contentsLoader,
        children: [
            {
                path: '/on-demand/',
                element: <OnDemand />,
            },
            {
                path: '/on-demand/:category',
                element: <OnDemand />,
            },
            {
                path: '/on-demand/:category/:content',
                element: <OnDemand />,
            },
            {
                path: '/on-demand/:category/:series/:season',
                element: <OnDemand />,
            },
            {
                path: '/on-demand/:category/:series/:season/:episode',
                element: <OnDemand />,
            }
        ]
    },
    {
        element: <Root active={ 'live tv' } />,
        loader: channelsLoader,
        children: [
            {
                path: '/',
                element: <LiveTV />,
            },
            {
                path: '/:category',
                element: <LiveTV />,
            },
            {
                path: '/:category/:channel',
                element: <LiveTV />,
            }
        ]
    },
    {
        element: <Root active={ 'terms of use' } />,
        children: [
            {
                path: '/terms-of-use/',
                element: <TermsOfUse />,
            },
        ]
    },
    {
        element: <Root active={ 'cookie policy' } />,
        children: [
            {
                path: '/cookie-policy/',
                element: <CookiePolicy />,
            },
        ]
    }
]);

export default router;
