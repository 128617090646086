import store from '../store/store';
import axiosApi from "../axiosApi";
import API_ROUTES from "../enum/apiRoutes";
import {setCollections, setRouteData} from "../store/onDemand/onDemandAction";

const loader = document.getElementById('loader');
const hideLoader = () => loader.remove();

export const contentsLoader = async ({ params }) => {
    const { category, content, series, season, episode } = params;
    const { data } = await axiosApi.get(API_ROUTES.collections);
    const categories = data?.response?.collections ?? [];

    if (category) {
        store.dispatch(setRouteData({
            category,
            content,
            series,
            season,
            episode
        }));
    }

    store.dispatch(setCollections(categories));

    hideLoader();

    return {};
}
