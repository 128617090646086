import { createReducer } from "@reduxjs/toolkit";
import {
    changeActiveCategory,
    changeActiveContent, changeActiveEpisode, changeActiveSeason,
    getContentByCollection, getContentById,
    setCollections, setPage,
    setRouteData
} from "./onDemandAction";

const initialState = {
    activeCategory: null,
    activeContent: null,
    activeSeason: null,
    activeEpisode: null,
    categories: [],
    contents: [],
    seasons: [],
    page: 1,
    limit: 28,
    currentPage: 1,
    loading: true,
    routeData: {
        category: null,
        content: null,
        series: null,
        season: null,
        episode: null,
    }
}

const getCategoryRoute = (state) => {
    return `/on-demand/${encodeURIComponent(state.activeCategory.title)}`;
}

const getContentRoute = (state) => {
    if (state.activeContent) {
        return `${getCategoryRoute(state)}/${encodeURIComponent(state.activeContent.name)}`;
    }
    return getCategoryRoute(state);
}

const getSeriesRoute = (state) => {
    if (state.activeSeason) {
        return `${getCategoryRoute(state)}/${encodeURIComponent(state.activeContent.name)}`;
    }
    return getCategoryRoute(state);
}

const getSeasonRoute = (state) => {
    if (state.activeSeason) {
        return `${getSeriesRoute(state)}/${encodeURIComponent(state.activeSeason.name)}`;
    }
    return getSeriesRoute(state);
}

const getEpisodeRoute = (state) => {
    if (state.activeEpisode) {
        return `${getSeasonRoute(state)}/${encodeURIComponent(state.activeEpisode.name)}`;
    }
    return getSeasonRoute(state);
}

const changeRoute = (path) => {
    window.history.replaceState(null, '', path);
}

export const onDemandReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setRouteData, (state, action) => {
            state.routeData = action.payload;
        })
        .addCase(setPage, (state, action) => {
            state.page = action.payload;
        })
        .addCase(setCollections, (state, action) => {
            state.categories = action.payload;

            if (state.routeData.category) {
                state.activeCategory = state.categories
                    .find(category => category.title === state.routeData.category);
                state.routeData.category = null;
            }

            if (! state.activeCategory) {
                state.activeCategory = state.categories.find(category => !!category) ?? null;
            }

            if (state.activeCategory) {
                changeRoute(getCategoryRoute(state));
            }
        })
        .addCase(getContentByCollection.pending, (state) => {
            state.loading = true;
        })
        .addCase(getContentByCollection.fulfilled, (state, action) => {
            state.loading = false;
            state.contents = state.contents.concat(action.payload)
                .filter(content => content.category.id === state.activeCategory.id);

            state.currentPage = state.page;

            if (action.payload.length === 0) {
                state.page -= 1;
            }

            if (state.routeData.content) {
                state.activeContent = state.contents
                    .find(content => encodeURIComponent(content.name) === encodeURIComponent(state.routeData.content)) ?? null;
                state.routeData.content = null;
            }

            if (state.routeData.series) {
                state.activeContent = state.contents.find(content => content.name === state.routeData.series);
                state.routeData.content = null;
            }

            changeRoute(getContentRoute(state));
        })
        .addCase(changeActiveCategory, (state, action) => {
            state.activeCategory = action.payload;
            state.page = 1;
            state.currentPage = 1;
        })
        .addCase(changeActiveContent, (state, action) => {
            state.activeContent = action.payload;
            state.seasons       = initialState.seasons;

            changeRoute(getContentRoute(state));
        })
        .addCase(changeActiveSeason, (state, action) => {
          state.activeSeason = action.payload;

          changeRoute(getSeasonRoute(state));
        })
        .addCase(getContentById.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(getContentById.fulfilled, (state, action) => {
            state.loading = false;

            state.seasons = [...action.payload];

            if (state.routeData.season) {
                state.activeSeason = state.seasons.find(season => season.name === state.routeData.season);
                state.routeData.season = null;

                changeRoute(getSeasonRoute(state));

                if (state.routeData.episode) {
                    const { episodes } = state.activeSeason;
                    state.activeEpisode = episodes.find(episode => episode.name === state.routeData.episode);
                    state.routeData.episode = null;

                    changeRoute(getEpisodeRoute(state));
                }
            } else {
                const seasonFirst = action.payload.find(season => !!season);

                if (seasonFirst) {
                    state.activeSeason = seasonFirst;

                    changeRoute(getSeasonRoute(state));
                }
            }
        })
        .addCase(changeActiveEpisode, (state, action) => {
          state.activeEpisode = action.payload;

          changeRoute(getEpisodeRoute(state));
        });
});