import store from '../store/store';
import axiosApi from "../axiosApi";
import API_ROUTES from "../enum/apiRoutes";
import {setCategories, setPathData} from "../store/liveTv/liveTvAction";

const loader = document.getElementById('loader');
const hideLoader = () => loader.remove();

export const channelsLoader = async ({ params }) => {
    const { category, channel } = params;

    const { data } = await axiosApi.get(`${ API_ROUTES.channels }?device=website`);
    const categories = data?.response?.categories ?? [];

    if (category && channel) {
        store.dispatch(setPathData({
            category,
            channel
        }));
    }

    store.dispatch(setCategories(categories));
    hideLoader();

    return { categories };
}
