import React, {useEffect, useState} from 'react';
import {readCookie} from "../../utils/cookie";
import cookie from './cookie.png';

import './styles.scss';

const GDPR = ({ close }) => {
    const [setting, setSetting] = useState(false);
    const [ads, setAds] = useState(false);

    useEffect(() => {
        const value = readCookie('airy_gdpr_accept');
        if (value && value === 'true') {
            setAds(true);
        }
    }, []);

    const disableCookie = () => {
        const date = new Date();
        date.setDate(date.getDate() + 180);
        document.cookie = "airy_gdpr_accept=false; expires=" + date.toGMTString() + "; path=/";
        close();
    }

    const acceptCookie = () => {
        const date = new Date();
        date.setDate(date.getDate() + 180);
        document.cookie = "airy_gdpr_accept=true; expires=" + date.toGMTString() + "; path=/";
        close();
    }

    const savePreference = () => {
      const value = ads ? 'true' : 'false';
        const date = new Date();
        date.setDate(date.getDate() + 180);
        document.cookie = `airy_gdpr_accept=${ value }; expires=` + date.toGMTString() + "; path=/";
        close();
    }

    const changeAds = (event) => {
        console.log(event.target.checked);
        setAds(event.target.checked);
    }

    return (
        <div className={'gdpr'}>
            <div className={'gdpr-back'} onClick={ close } />
            <div className={`gdpr-content ${setting ? 'active' : ''}`}>
                {
                    !setting &&
                    <>
                        <img src={cookie} alt="cookie" className={'gdpr-content-cookie'}/>
                        <h1>Cookie consent</h1>
                        <p>
                            We use cookies to improve your experience on our website.
                            We may store and/or access information on a device and process
                            personal data, such as your IP address and browsing data,
                            for personalised advertising and content.
                        </p>
                        <p>
                            You can change or withdraw your consent at any time.
                            We respect your choices and are committed to providing
                            you with a transparent and secure browsing experience.
                            <a href={'/cookie-policy/'}>Cookie Policy</a>
                        </p>
                    </>
                }
                {
                    setting &&
                    <>
                        <h3>Customize Cookie Preferences</h3>
                        <p>
                            We use cookies to help you navigate efficiently and perform
                            certain functions. You will find detailed information about
                            all cookies under each consent category below. The cookies that
                            are categorized as "Necessary" are stored on your browser as they
                            are essential for enabling the basic functionalities of the site.
                            We also use third-party cookies that help us analyze how you use
                            this website, store your preferences, and provide the content and
                            advertisements that are relevant to you. These cookies will only be
                            stored in your browser with your prior consent. You can choose to
                            enable or disable some or all of these cookies but disabling some of
                            them may affect your browsing experience.
                        </p>
                        <div className={'gdpr-content-type'}>
                            <h3>Necessary</h3>
                            <span>Always Active</span>
                        </div>
                        <p>
                            Necessary cookies are required to enable the basic features of this site,
                            such as providing secure log-in or adjusting your consent preferences.
                            These cookies do not store any personally identifiable data.
                        </p>
                        <div className={'gdpr-content-type'}>
                            <h3>Advertisement</h3>
                            <label className="switch">
                                <input type="checkbox" checked={ ads } onChange={ changeAds }/>
                                <span className="slider round"></span>
                            </label>
                        </div>
                        <p>
                            Advertisement cookies are used to provide visitors with customized
                            advertisements based on the pages you visited previously and to analyze
                            the effectiveness of the ad campaigns.
                        </p>
                    </>
                }

                <div className={'gdpr-content-footer'}>
                    {
                        !setting &&
                        <button onClick={() => setSetting(true)}>Customize</button>
                    }
                    <button onClick={disableCookie}>Reject All</button>
                    {
                        setting &&
                        <button onClick={savePreference}>Save My Preference</button>
                    }
                    <button onClick={acceptCookie} className={'active'}>Accept All</button>
                </div>
            </div>
        </div>
    );
}

export default GDPR;