import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import API_ROUTES from "../../enum/apiRoutes";
import axiosApi from "../../axiosApi";

export const getCollections = createAsyncThunk('getCollections', async () => {
    const { data } = await axiosApi.get(API_ROUTES.collections);

    return data?.response?.collections ?? [];
});

export const getContentByCollection = createAsyncThunk('getContentByCollection', async (payload) => {
    const { data } = await axiosApi.get(`${API_ROUTES.collection}/${payload.id}?page=${payload.page}&limit=${payload.limit}`);

    return data?.contents ?? [];
});

export const setPage = createAction('setPage', function (payload) {
    return { payload }
});

export const setCollections = createAction('setCollections', function (payload) {
    return { payload }
});

export const setPathData = createAction('setPathData', function (payload) {
    return { payload }
});

export const changeActiveCategory = createAction('changeActiveCategory', function (payload) {
    return { payload };
});

export const changeActiveContent = createAction('changeActiveContent', function (payload) {
    return { payload };
});

export const changeActiveSeason = createAction('changeActiveSeason', function (payload) {
    return { payload };
});

export const changeActiveEpisode = createAction('changeActiveEpisode', function (payload) {
    return { payload };
});

export const setRouteData = createAction('setRouteData', function (payload) {
    return { payload };
});

export const getContentById = createAsyncThunk('getContentById', async (payload) => {
    const { data } = await axiosApi.get(`${API_ROUTES.content}/${payload.id}`);

    return data?.seasons ?? [];
});
