import axios from 'axios';

const axiosApi = axios.create({
    baseURL: 'https://api.airy.tv/api/v2.1.7/',
    headers: {
        accept: 'application/json',
    },
});

// axiosApi.interceptors.request.use(
//     error => Promise.reject(error)
// );

export default axiosApi;
