const API_ROUTES = {
    channels: 'channels',
    collections: 'collections',
    collection: 'collection',
    content: 'content',
    login: 'token/create',
    check: 'token/check',
    register: 'register',
};

export default API_ROUTES;