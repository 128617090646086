import React, {useState} from "react";
import GDPR from "../GDPR";

import './styles.scss';

const CookiePolicy = () => {
    const [showGDPR, setShowGDPR] = useState(false);

    return (
        <>
            {
                showGDPR &&
                <GDPR
                    close={() => setShowGDPR(false)}
                />
            }
            <div className={'terms-container'}>
                <section className="terms-description">
                    <h1>COOKIE POLICY</h1>

                    <div className="terms-paragraph">
                        <h2>What are cookies?</h2>
                        <p>
                            This Cookie Policy explains what cookies are and how we use them,
                            the types of cookies we use i.e, the information we collect using
                            cookies and how that information is used, and how to manage
                            the cookie settings.
                        </p>
                        <p>
                            Cookies are small text files that are used to store small pieces
                            of information. They are stored on your device when the website is
                            loaded on your browser. These cookies help us make the website function
                            properly, make it more secure, provide better user experience, and
                            understand how the website performs and to analyze what works
                            and where it needs improvement.
                        </p>
                    </div>

                    <div className="terms-paragraph">
                        <h2>How do we use cookies?</h2>
                        <p>
                            As most of the online services, our website uses first-party and third-party
                            cookies for several purposes. First-party cookies are mostly necessary for
                            the website to function the right way, and they do not collect any of
                            your personally identifiable data.
                        </p>
                        <p>
                            The third-party cookies used on our website are mainly for understanding how
                            the website performs, how you interact with our website, keeping our services
                            secure, providing advertisements that are relevant to you, and all in all
                            providing you with a better and improved user experience and help speed up
                            your future interactions with our website.
                        </p>
                    </div>

                    <div className="terms-paragraph">
                        <h2>Types of Cookies we use</h2>
                        <h3>Necessary</h3>
                        <p>
                            Necessary cookies are required to enable the basic features of this site,
                            such as providing secure log-in or adjusting your consent preferences.
                            These cookies do not store any personally identifiable data.
                        </p>
                        <table>
                            <thead>
                            <tr>
                                <th>Cookie</th>
                                <th>Duration</th>
                                <th>Description</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>airy_user_token</td>
                                <td>1 day</td>
                                <td>Airy sets this cookie to identify the user's session instance after authorization.
                                </td>
                            </tr>
                            <tr>
                                <td>airy_gdpr_accept</td>
                                <td>180 days</td>
                                <td>Stores the selected settings for the use of cookies</td>
                            </tr>
                            </tbody>
                        </table>

                        <h3>Advertisement</h3>
                        <p>
                            Advertisement cookies are used to provide visitors with customized advertisements based on
                            the pages you visited previously and to analyze the effectiveness of the ad campaigns.
                        </p>
                        <table>
                            <thead>
                            <tr>
                                <th>Cookie</th>
                                <th>Duration</th>
                                <th>Description</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>DSID</td>
                                <td>2 weeks</td>
                                <td>
                                    Google DoubleClick IDE cookies are used to store information about
                                    how the user uses the website to present them with relevant ads
                                    and according to the user profile.
                                </td>
                            </tr>
                            <tr>
                                <td>IDE</td>
                                <td>2 years</td>
                                <td>
                                    Google DoubleClick IDE cookies are used to store information about
                                    how the user uses the website to present them with relevant ads
                                    and according to the user profile.
                                </td>
                            </tr>
                            <tr>
                                <td>ar_debug</td>
                                <td>1 year</td>
                                <td>This cookie is used by DoubleClick to debug ads.</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="terms-paragraph">
                        <h2>Manage cookie preferences</h2>
                        <button className={'active-button'} onClick={() => setShowGDPR(true)}>
                            Cookie Settings
                        </button>
                        <p>
                            You can change your cookie preferences any time by clicking the above button.
                            This will let you revisit the cookie consent banner and change your preferences
                            or withdraw your consent right away.
                        </p>
                        <p>
                            In addition to this, different browsers provide different methods to block and
                            delete cookies used by websites. You can change the settings of your browser to
                            block/delete the cookies. Listed below are the links to the support documents on
                            how to manage and delete cookies from the major web browsers.
                        </p>
                        <p>
                            Chrome: <a
                            href={'https://support.google.com/accounts/answer/32050'}>https://support.google.com/accounts/answer/32050</a>
                        </p>
                        <p>
                            Safari: <a
                            href={'https://support.apple.com/en-in/guide/safari/sfri11471/mac'}>https://support.apple.com/en-in/guide/safari/sfri11471/mac</a>
                        </p>
                        <p>
                            Firefox: <a
                            href={'https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=en-US'}>https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=en-US</a>
                        </p>
                        <p>
                            If you are using any other web browser, please visit your browser’s official support
                            documents.
                        </p>
                    </div>
                </section>
            </div>

        </>
    );
}

export default CookiePolicy;