import axios from 'axios';

const securityApi = axios.create({
    baseURL: 'https://api.airy.tv/security/',
    headers: {
        accept: 'application/json',
    },
});

export default securityApi;
