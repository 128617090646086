export const secondsToTime = (seconds) => {
    let hours = (seconds - seconds % 3600) / 3600;
    let minutes = ((seconds - (hours * 3600)) - (seconds - (hours * 3600)) % 60) / 60;
    minutes = minutes < 10 ? `0${minutes}` : minutes;

    if (!hours) {
        return `${minutes}m`;
    }

    hours = hours < 10 ? `0${hours}` : hours;

    return `${hours}h ${minutes}m`
}

export const listGuideTimes = () => {
    let times = [];
    let minutes = new Date().getMinutes();
    let hours = new Date().getHours();
    let format = hours < 12 ? 'AM' : 'PM';

    if (minutes > 30) {
        minutes = 30;
    } else {
        minutes = 0;
    }

    for (let i = 0; i < 16; i++) {
        if (hours === 12) {
            format = format === 'AM' ? 'PM' : 'AM';
        }
        times.push(`${formatHour(hours) < 10 ? `0${formatHour(hours)}` : formatHour(hours)}:${minutes < 10 ? `0${minutes}` : minutes} ${ format }`);
        minutes = minutes + 30;
        if (minutes === 60) {
            hours = hours + 1;
            minutes = 0;

            if (hours === 24) {
                hours = 0;
                format = format === 'AM' ? 'PM' : 'AM';
            }
        }
    }

    return times;
}

const formatHour = (hour) => {
    if (hour === 0) {
        hour = 12;
    }

    if (hour > 12) {
        hour = hour - 12;
    }

    return hour;
}
