import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import API_ROUTES from "../../enum/apiRoutes";
import axiosApi from "../../axiosApi";

export const getCategories = createAsyncThunk('getCategories', async () => {
    const { data } = await axiosApi.get(`${ API_ROUTES.channels }?device=website`);

    return data?.response?.categories ?? [];
});

export const setCategories = createAction('setCategories', function (payload) {
    return { payload }
});

export const setPathData = createAction('setPathData', function (payload) {
    return { payload }
});

export const nextChannel = createAction('nextChannel');

export const previousChannel = createAction('previousChannel');

export const changeActiveChannel = createAction('changeActiveChannel', function (payload) {
    return { payload };
})
