import React from "react";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as PlayCircleIcon } from "../../assets/icons/play_circle.svg";
import { ReactComponent as ScreenIcon } from "../../assets/icons/screen.svg";
import { ReactComponent as QRCodeAppleIcon } from "../../assets/images/qr-code-apple.svg";
import { ReactComponent as QRCodeGoogleIcon } from "../../assets/images/qr-code-google.svg";
import { ReactComponent as LoginIcon } from "../../assets/icons/login.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout.svg";
import { ReactComponent as AvatarIcon } from "../../assets/icons/avatar.svg";

import './style.scss';

const SubMenu = ({ user, setUser, active, toggleSubMenu, state, setLoginOpen }) => {
    // TODO: added modal for list events and subscriptions and closed

    return (
        <div className={`submenu submenu-${ state }`}>
            <div className={'submenu-data'}>
                <div className={'submenu-data-logo'}>
                    <CloseIcon className={'submenu-data-logo-close'} onClick={toggleSubMenu}/>
                </div>
                {
                    !user &&
                    <p className={'submenu-data-item'} onClick={() => setLoginOpen(true)}>
                        <LoginIcon/>
                        Sign In
                    </p>
                }
                {
                    user &&
                    <>
                        <p className={'submenu-data-item'}>
                            <AvatarIcon/>
                            {user.email}
                        </p>
                        <p className={'submenu-data-item'} onClick={() => setUser(null)}>
                            <LogoutIcon/>
                            Logout
                        </p>
                    </>
                }
                <h3>
                    WATCH ON AIRY WEB APP
                </h3>
                <a href={'/'} className={`submenu-data-item ${active === 'live tv' ? 'active' : ''}`}>
                    <ScreenIcon/>
                    Live TV
                </a>
                <a href={'/on-demand/'} className={`submenu-data-item ${active === 'on demand' ? 'active' : ''}`}>
                    <PlayCircleIcon/>
                    On Demand
                </a>
                <h3>
                    AIRYTV WEBSITE NAVIGATION
                </h3>
                <a href={'https://airy.tv/'} className={'submenu-data-item'}>
                    Home
                </a>
                <a href={'https://airy.tv/'} className={'submenu-data-item'}>
                    Give Us Your Feedback
                </a>
                <a href={'https://airy.tv/contact-us/'} className={'submenu-data-item'}>
                    Support Contact
                </a>
                <a href={'https://airy.tv/content-producers/'} className={'submenu-data-item'}>
                    Become a Content Partner
                </a>
                <h3>
                    LEGAL
                </h3>
                <a href={'/terms-of-use/'} className={`submenu-data-item ${active === 'terms of use' ? 'active' : ''}`}>
                    Terms of Use
                </a>
                <a href={'/cookie-policy/'} className={`submenu-data-item ${active === 'cookie policy' ? 'active' : ''}`}>
                    Cookie Policy
                </a>
                <a href={'https://airy.tv/privacy-policy/'} className={'submenu-data-item'}>
                    Privacy Policy
                </a>
                <h3>
                    INSTALL OUR APPS
                </h3>
                <div className={'submenu-data-codes'}>
                    <a href={'https://apps.apple.com/us/app/airy-tv-live-movie-streaming/id1474220532'}
                       className={'submenu-data-codes-item'}>
                        <p>
                            IOS Scan
                        </p>
                        <QRCodeAppleIcon className={'qr-code'}/>
                    </a>
                    <a href={'https://play.google.com/store/apps/details?id=com.freeairytv.android'}
                       className={'submenu-data-codes-item'}>
                        <p>
                            Android Scan
                        </p>
                        <QRCodeGoogleIcon className={'qr-code'}/>
                    </a>
                </div>
            </div>
            <div className={'submenu-background'} onClick={toggleSubMenu}/>
        </div>
    );
}

export default SubMenu;
