import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {ReactComponent as CloseIcon} from "../../../assets/icons/close.svg";
import {ReactComponent as PlayIcon} from "../../../assets/icons/play_arrow.svg";

const PlayerModal = ({ item, closeModal }) => {
  const {activeContent} = useSelector((state) => state.onDemand);
  const [play, setPlay] = useState(false);
  const { name, title, description, sourceUrl } = item;

  useEffect(() => {
    setPlay(false);
  }, [activeContent]);

  const handleCloseModal = () => {
    setPlay(false);
    closeModal();
  }

  return (
    <>
      <div className={'on-demand-info'}>
        <div className={'on-demand-info-data'}>
          <div className={'on-demand-info-data-close'}>
            <CloseIcon onClick={ handleCloseModal }/>
          </div>
          <div className={'on-demand-info-data-poster'}>
            <div
              className={'on-demand-info-data-poster-image'}
              style={{backgroundImage: `url(${item.posters.mobile})`}}
            />
          </div>
          <div className={'on-demand-info-data-title'}>
            {name}
          </div>
          <div className={'on-demand-info-data-details'}>
            <div className={'on-demand-info-data-details-category'}>{title}</div>
            <div className={'on-demand-info-data-details-label'}></div>
          </div>
          <div className={'on-demand-info-data-desc'}>
            {description}
          </div>
        </div>

        <div className={'on-demand-info-player'}>
          {!play && <div className={'on-demand-info-player-button'}>
            <div className={'on-demand-info-player-button-play'}>
              <PlayIcon onClick={() => setPlay(true)}/>
            </div>
          </div>
          }
          {play && <div className={'on-demand-info-player-video'}>
            <video controls={true}
                   controlsList="nodownload"
                   autoPlay={play}
                   muted={true}
                   src={sourceUrl}
            />
          </div>
          }
        </div>
      </div>
    </>
  )
}

export default PlayerModal;