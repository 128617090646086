import { createReducer } from "@reduxjs/toolkit";
import {
    changeActiveChannel, getCategories,
    nextChannel,
    previousChannel,
    setCategories,
    setPathData
} from "./liveTvAction";

const initialState = {
    loading: true,
    activeCategory: '',
    activeChannel: '',
    categories: [],
};

export const liveTvReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(getCategories.fulfilled, (state, action) => {
            state.categories = action.payload;
            if (state.activeChannel && state.activeCategory) {
                state.categories.forEach(category => {
                    if (category.name === state.activeCategory) {
                        category.stream_channels.forEach(channel => {
                            if (state.activeChannel.id === channel.id) {
                                state.activeChannel = channel;
                            }
                        });
                    }
                });
            }
        })
        .addCase(setCategories, (state, action) => {
            state.loading = false;
            state.categories = action.payload;

            if (state.activeChannel && state.activeCategory) {
                state.categories.forEach(category => {
                    if (category.name === state.activeCategory) {
                        category.stream_channels.forEach(channel => {
                            if (state.activeChannel === `${channel.number}_${channel.name}`) {
                                state.activeChannel = channel;
                            }
                        });
                    }
                });
            }

            if (typeof state.activeChannel !== 'object') {
                for (let categoryKey in state.categories) {
                    let category = state.categories[categoryKey];

                    for (let channelKey in category.stream_channels) {
                        state.activeChannel = category.stream_channels[channelKey];
                        state.activeCategory = category.name;
                        break;
                    }

                    if (typeof state.activeChannel === 'object') {
                        break;
                    }
                }


                if (typeof state.activeChannel === 'object') {
                    window.history.replaceState(
                        null,
                        '',
                        `/${state.activeCategory}/${state.activeChannel.number}_${encodeURIComponent(state.activeChannel.name)}`
                    );
                }
            }
        })
        .addCase(changeActiveChannel, (state, action) => {
            state.activeChannel = action.payload;
            state.categories.forEach(category => {
                category.stream_channels.forEach(channel => {
                    if (channel.id === state.activeChannel.id) {
                        state.activeCategory = category.name;
                    }
                })
            });

            window.history.replaceState(
                null,
                '',
                `/${state.activeCategory}/${state.activeChannel.number}_${encodeURIComponent(state.activeChannel.name)}`
            );
        })
        .addCase(setPathData, (state, action) => {
            state.activeChannel = action.payload.channel;
            state.activeCategory = action.payload.category;
        })
        .addCase(nextChannel, (state) => {
            let activeCategoryKey = 0;
            let activeChannelKey = 0;
            for (let categoryKey in state.categories) {
                let category = state.categories[categoryKey];
                if (category.name === state.activeCategory) {
                    activeCategoryKey = Number(categoryKey);

                    for (let channelKey in category.stream_channels) {
                        let channel = category.stream_channels[channelKey];
                        if (channel.id === state.activeChannel.id) {
                            activeChannelKey = Number(channelKey) + 1;
                            break;
                        }
                    }
                    break;
                }
            }

            if (!state.categories[activeCategoryKey].stream_channels.hasOwnProperty(activeChannelKey)) {
                activeChannelKey = 0;
                activeCategoryKey = activeCategoryKey + 1;

                if (!state.categories.hasOwnProperty(activeCategoryKey)) {
                    activeCategoryKey = 0;
                }
            }

            state.activeCategory = state.categories[activeCategoryKey].name;
            state.activeChannel = state.categories[activeCategoryKey].stream_channels[activeChannelKey];

            window.history.replaceState(
                null,
                '',
                `/${state.activeCategory}/${state.activeChannel.number}_${encodeURIComponent(state.activeChannel.name)}`
            );
        })
        .addCase(previousChannel, (state) => {
            let activeCategoryKey = 0;
            let activeChannelKey = 0;
            for (let categoryKey in state.categories) {
                let category = state.categories[categoryKey];
                if (category.name === state.activeCategory) {
                    activeCategoryKey = Number(categoryKey);

                    for (let channelKey in category.stream_channels) {
                        let channel = category.stream_channels[channelKey];
                        if (channel.id === state.activeChannel.id) {
                            activeChannelKey = Number(channelKey) - 1;
                            if (activeChannelKey < 0) {
                                activeCategoryKey = activeCategoryKey - 1;
                                if (activeCategoryKey < 0) {
                                    activeCategoryKey = state.categories.length - 1;
                                }

                                activeChannelKey = state.categories[activeCategoryKey].stream_channels.length - 1;
                            }
                            break;
                        }
                    }
                    break;
                }
            }

            state.activeCategory = state.categories[activeCategoryKey].name;
            state.activeChannel = state.categories[activeCategoryKey].stream_channels[activeChannelKey];

            window.history.replaceState(
                null,
                '',
                `/${state.activeCategory}/${state.activeChannel.number}_${encodeURIComponent(state.activeChannel.name)}`
            );
        });
});
