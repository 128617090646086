import React, {useEffect, useState} from "react";
import {ReactComponent as CloseIcon} from "../../assets/icons/close.svg";
import {ReactComponent as PlayIcon} from "../../assets/icons/play_arrow.svg";
import {useDispatch, useSelector} from "react-redux";
import {
    changeActiveCategory,
    changeActiveContent,
    changeActiveEpisode,
    changeActiveSeason,
    getContentByCollection,
    getContentById,
    setPage
} from "../../store/onDemand/onDemandAction";
import ITEM_TYPE from "../../enum/ItemType";
import {secondsToTime} from "../../utils/time";
import PlayerModal from "../../components/Modal/PlayerModal";
import ShowferPlayer from "showfer-player";
import {readCookie} from "../../utils/cookie";

import './style.scss';


const OnDemand = () => {
    const { categories, contents, seasons, activeCategory, activeContent, activeSeason, page, limit, loading, currentPage, activeEpisode } = useSelector((state) => state.onDemand);
    const [play, setPlay] = useState(false);
    const episodes = activeSeason?.episodes || [];
    const dispatch = useDispatch();

    const [disabledCookie, setDisabledCookie] = useState(false);

    useEffect(() => {
        const acceptCookie = readCookie('airy_gdpr_accept');
        if (acceptCookie && acceptCookie === 'false') {
            setDisabledCookie(true);
        }
    }, []);

    useEffect(() => {
        setPlay( false );
    }, [ activeContent ]);

    useEffect(() => {
        if (activeCategory) {
            dispatch(getContentByCollection({
                page,
                limit,
                id: activeCategory.id
            }))
        }
    }, [ activeCategory ]);

    useEffect(() => {
        if ( activeContent && activeContent.type === ITEM_TYPE.series ) {
            dispatch(getContentById({
                id: activeContent.id
            }));
        }
    }, [ activeContent ]);

    const setActiveContent = (content) => {
        dispatch(changeActiveContent(content));
        setActiveSeason(content);
    }

    const setActiveCategory = (category) => {
        dispatch(changeActiveCategory(category));
    }

    const handleContentScroll = (event) => {
        const element = event.target;
        if (element.scrollTop >= (element.scrollHeight - element.clientHeight) * 0.7 && page === currentPage) {
            dispatch(setPage(page + 1));
            dispatch(getContentByCollection({
                page: page + 1,
                limit,
                id: activeCategory.id
            }))
        }
    }

    const setActiveSeason = (season) => {
        dispatch(changeActiveSeason(season));
    }

    const setActiveEpisode = (episode) => {
        dispatch(changeActiveEpisode(episode));
    }

    return (
        <>
            { activeContent &&
                <div className={'on-demand-info'}>
                    <div className={'on-demand-info-data'}>
                        <div className={'on-demand-info-data-close'}>
                            <CloseIcon onClick={ () => setActiveContent(null) } />
                        </div>
                        <div className={'on-demand-info-data-poster'}>
                            <div
                                className={'on-demand-info-data-poster-image'}
                                style={{backgroundImage: `url(${ activeContent.posters.mobile })`}}
                            />
                        </div>
                        <div className={'on-demand-info-data-title'}>
                            { activeContent.name }
                        </div>
                        <div className={'on-demand-info-data-details'}>
                            <div className={'on-demand-info-data-details-category'}>{activeCategory.title}</div>
                            <div className={'on-demand-info-data-details-label'}></div>
                        </div>
                        <div className={'on-demand-info-data-desc'}>
                            { activeContent.description }
                        </div>
                    </div>

                    { activeContent.type === ITEM_TYPE.movie &&
                        <div className={'on-demand-info-player'}>
                            { !play && <div className={'on-demand-info-player-button'}>
                                    <div className={'on-demand-info-player-button-play'}>
                                        <PlayIcon onClick={() => setPlay(true)} />
                                    </div>
                                </div>
                            }
                            {
                                play && <div className={'on-demand-info-player-video'}>
                                    <ShowferPlayer
                                        muted={false}
                                        volume={ 1 }
                                        url={ activeContent.sourceUrl }
                                        controls={ false }
                                        type={'vod'}
                                        disabledCookie={ disabledCookie }
                                        subUrl={ activeContent?.subtitleUrl }
                                    />
                                </div>
                            }
                        </div>
                    }

                    { activeContent.type === ITEM_TYPE.series &&
                      <div className={'on-demand-info-seasons'}>
                          <div className="on-demand-info-seasons-list">
                              {seasons.map(season => (
                                <span key={season.id} className={`on-demand-info-seasons-btn ${season.id === activeSeason.id ? 'active' : ''}`}
                                      onClick={() => setActiveSeason(season)}
                                >
                                    {season.name}
                                </span>
                              ))}
                          </div>

                          { episodes.map( episode => (
                            <div className="on-demand-info-seasons-episode-item" onClick={() => setActiveEpisode(episode)}>

                                { activeEpisode && <PlayerModal item={ episode } closeModal={ () => setActiveEpisode(null) } /> }

                                <div className={'on-demand-info-seasons-episode-item-image'}>
                                    <img src={episode.posters.mobile} alt={episode.name}/>
                                </div>
                                <div className={'on-demand-info-seasons-episode-item-detail'}>
                                    <div className={'on-demand-info-seasons-episode-item-detail-name'} >
                                        {episode.name}
                                    </div>
                                    { episode.number > 0 &&
                                      <div className={'on-demand-info-seasons-episode-item-detail-number'}>
                                          <p>Episode number: {episode.number}</p>
                                      </div>
                                    }
                                    {episode.description &&
                                      <div className={'on-demand-info-seasons-episode-item-detail-description'}>
                                          {episode.description}
                                      </div>
                                    }
                                    <div className={'on-demand-info-seasons-episode-item-detail-time'}>
                                        {secondsToTime(episode.duration)}
                                    </div>
                                </div>
                            </div>
                          ))}
                      </div>
                    }
                </div>
            }

            <div className={'on-demand'}>
                <div className={'on-demand-category'}>
                    { categories.map(category => (
                        <div className={`on-demand-category-item ${ category.title === activeCategory.title ? 'active' : '' }`}
                             onClick={ () => setActiveCategory(category) }
                        >
                            { category.title }
                        </div>
                    ))}
                </div>
                <div className={'on-demand-content'} onScroll={ (event) => handleContentScroll(event) }>
                    { contents.map(content => (
                        <div className={'on-demand-content-item'}
                             onClick={ () => setActiveContent(content) }
                             title={ content.name }
                             style={{backgroundImage: `url(${ content.posters.mobile })`}}>
                        </div>
                    ))}
                    { loading && <div className={'on-demand-content-loader'}>
                            <div className={'loader-ring'}>
                                <div className={'loader-ring-light'} />
                                <div className={'loader-ring-track'}>Loading</div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    );
}

export default OnDemand;