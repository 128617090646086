import React from "react";

import './styles.scss';

const TermsOfUse = () => {
    return (
        <div className={'terms-container'}>
            <section className="terms-description">
                <h1>AIRYTV TERMS OF USE</h1>

                <div className="terms-paragraph">
                    <h2>Terms of Use Showfer Media Service End-User License Agreement</h2>
                    <p>
                        Thank you for accessing the Airy TV, WooHoo TV (the “Website”) or Airy
                        TV, WooHoo TV mobile applications (the “App”, and together with the
                        Website, the “Service”), as made available on mobile devices utilizing
                        the Android® or iOS operating system, as well as other mobile devices
                        where and to the extent available (collectively, “Mobile Device”), by
                        and through the Google Play® store, Apple Store® and other applicable
                        venues (“Download Venues”). The Service is owned and operated by
                        Showfer Media LLC (“Showfer Media,” “we,” “our” or “us”) a Delaware
                        Corporation.
                    </p>
                    <p>
                        The Service provides end-user visitors (“Visitors”) with: (a) access
                        to certain text, images, video, audio and other content and
                        information relating to the Showfer Media Offerings (as defined below)
                        (collectively, “Showfer Media Content”) and (b) a means to register as
                        a member (“Member”), either through the Service or through the
                        applicable Visitor’s Facebook® account or Google+ account; and
                        together with the Service, and Showfer Media Content, the “Showfer
                        Media Offerings”.
                    </p>
                    <p>
                        The following Showfer Media Service End-User License Agreement
                        (“EULA”) is inclusive of the Showfer Media Mobile Service Privacy
                        Policy (“Privacy Policy”) and any and all other applicable Showfer
                        Media operating rules, policies, price schedules and other
                        supplemental terms and conditions or documents that may be published
                        from time to time, which are expressly incorporated herein by
                        reference (collectively, and together with this EULA, the
                        “Agreement”). For purposes of the Agreement, Members and Visitors
                        shall be referred to, collectively, as “End-Users.” By using and/or
                        accessing the Showfer Media Offerings, End-Users agree to comply with
                        and be bound by the Agreement in its entirety. PLEASE REVIEW THE TERMS
                        OF THE AGREEMENT CAREFULLY. IF AN END-USER DOES NOT AGREE TO THE TERMS
                        OF THE AGREEMENT IN THEIR ENTIRETY, THAT END-USER IS NOT AUTHORIZED TO
                        USE THE SHOWFER MEDIA OFFERINGS IN ANY MANNER OR FORM.
                    </p>
                    <p>
                        THE AGREEMENT CONTAINS DISCLAIMERS OF WARRANTIES, LIMITATIONS OF
                        LIABILITY, RELEASES, A CLASS-ACTION WAIVER, AND THE REQUIREMENT TO
                        ARBITRATE ANY AND ALL CLAIMS THAT MAY ARISE HEREUNDER. THE
                        AFOREMENTIONED PROVISIONS ARE AN ESSENTIAL BASIS OF THE AGREEMENT.
                    </p>
                    <p>
                        NEW JERSEY STATE RESIDENTS ARE ENCOURAGED TO REVIEW THEIR RIGHTS UNDER
                        THE AGREEMENT, AS PROVIDED UNDER THE NEW JERSEY TRUTH-IN-CONSUMER
                        CONTRACT WARRANTY AND NOTICE ACT (“TCCWNA”).
                    </p>
                    <p>
                        Android® and Google Play® are registered trademarks of Google, Inc.
                        (“Google”). Apple Store® is a registered trademark of Apple Inc.
                        (“Apple”). Facebook® is a registered trademark of Facebook, Inc.
                        (“Facebook”). Please be advised that Showfer Media is not in any way
                        affiliated with Facebook, Apple or Google, and the Showfer Media
                        Offerings are not endorsed, administered or sponsored by Facebook,
                        Apple or Google.
                    </p>
                </div>

                <ol className="terms-list">
                    <li>
                        <h3>Scope; Modification of Agreement</h3>
                        <p>
                            The Agreement constitutes the
                            entire and only agreement between End-Users and Showfer Media with
                            respect to End-Users’ use of the Showfer Media Offerings, and
                            supersedes all prior or contemporaneous agreements, representations,
                            warranties and/or understandings with respect to same. We may amend
                            the Agreement from time to time in our sole discretion, without
                            specific notice to our End-Users; provided, however, that any
                            amendment or modification to the arbitration provisions, prohibition
                            on class action provisions or any other provisions applicable to
                            dispute resolution (collectively, “Dispute Resolution Provisions”)
                            shall not apply to any disputes incurred prior to the applicable
                            amendment or modification. The latest Agreement will be posted by and
                            through the Service, and End-Users should review the Agreement prior
                            to using any Showfer Media Offerings. By an End-User’s continued use
                            of the Showfer Media Offerings, that End-User hereby agrees to comply
                            with all of the terms and conditions contained within the Agreement
                            effective at that time (other than with respect to disputes arising
                            prior to the amendment or modification of the Dispute Resolution
                            Provisions, which shall be governed by the Dispute Resolution
                            Provisions then in effect at the time of the subject dispute).
                        </p>
                    </li>
                    <li>
                        <h3>Requirements; Necessary Equipment; Wireless Charges</h3>
                        <p>
                            The Showfer Media Offerings are not intended for use by: (a) individuals who are
                            unable to enter into legally binding contracts under applicable law;
                            and/or (b) individuals under thirteen (13) years of age (or the
                            applicable age of majority, if greater than thirteen (13) years of
                            age). If you are under thirteen (13) years of age (or the applicable
                            age of majority if greater than thirteen (13) years of age) and/or if
                            you are unable to enter into legally binding contracts under
                            applicable law, you do not have permission to use and/or access the
                            Showfer Media Offerings.
                        </p>
                        <p>
                            End-Users shall be responsible, at all times, for ensuring that they
                            have a Mobile Device, Internet connection, wireless service plan
                            and/or other equipment necessary to access and use the Service and
                            other Showfer Media Offerings. Showfer Media does not guarantee that
                            the Service and associated Showfer Media Offerings can be accessed:
                            (i) on all Mobile Devices; (ii) through all wireless service plans;
                            and/or (iii) in all geographical areas. Showfer Media does not
                            guarantee the quality, speed or availability of your Mobile Device’s
                            Internet connection. Standard messaging, data and wireless access fees
                            may apply to your use of the Service and associated Showfer Media
                            Offerings. You are fully responsible for all such charges, and Showfer
                            Media has no liability or responsibility to you, whatsoever, for any
                            such charges billed by your wireless carrier.
                        </p>
                    </li>
                    <li>
                        <h3>Installation/Uninstall of the App</h3>
                        <p>
                            Installation. Showfer Media has made commercially reasonable efforts
                            to provide End-Users with clear, concise and complete disclosure
                            before End-Users download/install the App or access/use the Showfer
                            Media Offerings, including a description of the primary functions of
                            the App. The App requires End-User consent prior to installation.
                        </p>
                        <p>
                            Uninstall. The App can be completely uninstalled in a straightforward
                            manner and without requiring undue effort or skill, in most cases by
                            using the traditional "add/remove" programs function contained within
                            your Mobile Device. Please be advised that in some instances, you may
                            be required to restart your Mobile Device before all remnants of the
                            App are completely uninstalled and removed from your Mobile Device.
                        </p>
                        <p>
                            THE APP IS NOT SPYWARE OR ADWARE. THE APP WILL NOT DELIVER
                            ADVERTISEMENTS TO YOUR MOBILE DEVICE, ALTHOUGH YOU MAY BE SHOWN
                            ADVERTISEMENTS WITHIN THE APP INTERFACE.
                        </p>
                    </li>
                    <li>
                        <h3>Registration; Account Rejection and/or Termination</h3>
                        <p>
                            In order to
                            utilize certain of the Showfer Media Offerings, End-Users may be
                            required to submit a registration form (each, a “Form”) with
                            information (“Service Registration Data”) that varies depending on
                            whether the End-User is attempting to register via her/his Facebook®
                            account.
                        </p>
                        <p>
                            The information that End-Users must supply on the applicable Form may
                            include, without limitation: (a) the End-User’s full name; (b) the
                            End-User’s screen name; (c) the End-User’s e-mail address; (d) the
                            End-User’s mailing address; (e) the End-User’s date of birth; (f) the
                            End-User’s gender; and/or (g) any other information requested by
                            Showfer Media on the applicable Form (collectively, “Showfer Media
                            Registration Data”). Where an End-User accesses certain of the Showfer
                            Media Offerings using her/his Facebook® account, Showfer Media may
                            collect some or all of the following (depending on the End-User’s
                            Facebook® account settings and the discretion of Facebook®): (i) the
                            End-User’s e-mail address; (ii) the information listed in the “About
                            Me” section of the End-User’s Facebook® account; (iii) the “interests”
                            associated with the End-User’s Facebook® account; (iv) the End-User’s
                            Facebook® account ID and the “likes” associated with the End-User’s
                            Facebook® account; (v) the End-User’s Facebook® profile picture; and
                            (vi) any other information collected via the Facebook® account
                            interface, depending on the End-User’s Facebook® account settings
                            (collectively, “Facebook® Registration Data,” and together with the
                            Service Registration Data and Showfer Media Registration Data, the
                            “Registration Data”).
                        </p>
                        <p>
                            Each End-User agrees to provide true, accurate, current and complete
                            Registration Data, as necessary, in order to maintain it in up to date
                            and accurate fashion. Showfer Media may reject an End-User’s Form,
                            reject an End-User’s attempted Facebook® registration and/or terminate
                            an End-User’s Showfer Media Offerings account (“Account”) at any time
                            and for any reason, in its sole discretion. Such reasons may include,
                            without limitation, where: (A) Showfer Media believes that such
                            End-User is in any way in breach of the Agreement; (B) Showfer Media
                            believes that such End-User is engaged in any improper conduct in
                            connection with the Showfer Media Offerings; and/or (C) Showfer Media
                            believes that such End-User is, at any time, conducting any
                            unauthorized commercial activity by and/or through the Showfer Media
                            Offerings.
                        </p>
                        <p>
                            As part of the registration process, End-Users will be provided with,
                            or must select, a user name; provided, that, where an End-User
                            accesses certain of the Showfer Media Offerings using her/his
                            Facebook® account, that User’s Facebook® account user name may be
                            automatically assigned as that End-User’s Showfer Media user name. If
                            the user name that an End-User requests is not available, that
                            End-User will be asked to supply another user name. If Showfer Media
                            provides an End-User with a user name, that End-User can change that
                            user name, or the one that the End-User selected during registration,
                            at any time through her/his Account settings. Each End-User agrees to
                            notify Showfer Media of any known or suspected unauthorized use(s) of
                            her/his Account, or any known or suspected breach of security,
                            including loss, theft, or unauthorized disclosure of her/his user
                            name. Each End-User shall be responsible for maintaining the
                            confidentiality of her/his user name and Account. Each End-User agrees
                            to accept responsibility for all activities that occur through use of
                            her/his user name and Account. Any fraudulent, abusive or otherwise
                            illegal activity engaged in by any End-User, or any entity or person
                            with access to that End-User’s user name and/or Account may be grounds
                            for termination of that End-User’s Account, in Showfer Media’s sole
                            discretion, and that End-User may be reported to appropriate law
                            enforcement agencies.
                        </p>
                    </li>
                    <li>
                        <h3>The Showfer Media Offerings; Legal Compliance</h3>
                        <ul className="terms-list-letters">
                            <li>
                                <h4>Compliance with Applicable Law</h4>
                                <p>
                                    By accessing and using the Showfer
                                    Media Offerings, each End-User agrees to use the Showfer Media
                                    Offerings in accordance with all applicable Showfer Media guidelines,
                                    as well as all applicable local, state, national, federal and
                                    international laws (collectively, “Applicable Law”)
                                </p>
                            </li>
                            <li>
                                <h4>App</h4>
                                <p>
                                    Download Venues. The Agreement is entered into between you and Showfer
                                    Media, and not with the applicable Download Venue that you use to
                                    access the App. As between Showfer Media and any participating
                                    Download Venue, Showfer Media is solely responsible for the App. No
                                    Download Venue has any obligation to furnish any maintenance and/or
                                    support services with respect to the App. The Download Venues are
                                    third-party owned and operated websites. Use of those stores shall be
                                    governed by the applicable venue’s agreements, terms and conditions.
                                    Showfer Media does not control the Download Venues or any of the
                                    actions, policies or decisions made by the operators of those stores.
                                    If you accessed or downloaded the App from the Apple® Store, then you
                                    agree to use the App only: (i) on an Apple®-branded product or device
                                    that runs iOS (Apple’s® proprietary operating system software); and
                                    (ii) as permitted by the “Usage Rules” set forth in the Apple® Store
                                    Terms of Service, as applicable.
                                </p>
                                <p>
                                    Basic Functionality. For purposes of the Agreement, the term “App”
                                    includes all Showfer Media and third-party software applications,
                                    files, associated media, printed materials and/or electronic
                                    documentation that may be used in connection with the App’s
                                    functionality. The App enables End-Users to utilize the Platform and
                                    API by and through their Mobile Devices. Each End-User acknowledges
                                    and agrees that access to, and the functionality of, the App may be
                                    interrupted and limited, and may not be error free. Each End-User
                                    understands and agrees that Showfer Media shall not be liable to any
                                    End-User or third-party for any claim in connection with such
                                    End-User’s use of, or inability to use, the App.
                                </p>
                                <p>
                                    Updates/Bug Fixes. Showfer Media reserves the right (but is not
                                    obligated) to add additional features or functions to the existing
                                    App, and to provide bug fixes, error corrections, patches, new
                                    releases or any other component not specified within this EULA, from
                                    time to time. When installed on an End-User’s Mobile Device, the App
                                    periodically communicates with Showfer Media servers. Showfer Media
                                    may require the updating of the App residing on an End-User’s Mobile
                                    Device when Showfer Media releases a new version of the App, or when
                                    Showfer Media makes new features available. This update may occur
                                    automatically or upon prior notice to the End-User and may occur all
                                    at once or over multiple sessions, in Showfer Media’s sole and
                                    absolute discretion. Each End-User understands that we may require
                                    that End-User’s review and acceptance of our then-current Agreement
                                    before that End-User will be permitted to use any subsequent versions
                                    of the App. Each End-User acknowledges and agrees that Showfer Media
                                    has no obligation to make any subsequent versions of the App available
                                    to that End-User, or to provide bug fixes, error corrections, patches,
                                    new releases or any other component not specified within the
                                    Agreement.
                                </p>
                                <p>
                                    Third-party Software. ANY THIRD-PARTY SOFTWARE, AS WELL AS ANY
                                    THIRD-PARTY PROVIDED PLUG-INS, THAT MAY BE PROVIDED WITH THE APP ARE
                                    MADE AVAILABLE FOR USE AT EACH END-USER’S SOLE OPTION AND RISK. IF AN
                                    END-USER CHOOSES TO USE SUCH THIRD-PARTY SOFTWARE, THEN SUCH USE SHALL
                                    BE GOVERNED BY SUCH THIRD-PARTY\'S LICENSING AGREEMENT(S), TERMS AND
                                    CONDITIONS AND PRIVACY PRACTICES. SHOWFER MEDIA IS NOT RESPONSIBLE FOR
                                    ANY THIRD-PARTY SOFTWARE AND SHALL HAVE NO LIABILITY WHATSOEVER FOR
                                    ANY END-USER’S USE OF, OR INABILITY TO USE, THIRD-PARTY SOFTWARE.
                                </p>
                                <p>
                                    License Grant/Termination. Subject to the terms and conditions of the
                                    Agreement, Showfer Media hereby grants to each End-User a personal,
                                    non-exclusive, non-transferable, revocable and limited license to
                                    access and use the App on Mobile Devices, where such Mobile Devices
                                    are owned or leased by such End-User. Showfer Media may terminate the
                                    license set forth in this Section 5(b) and/or disable, remove or
                                    change the App and/or any portion thereof in its sole discretion at
                                    any time, with or without notice, by remote updates or otherwise. The
                                    license set forth in this Section 5(b) shall be in effect unless and
                                    until this license is terminated by Showfer Media. In addition, this
                                    license will terminate immediately with respect to an End-User if that
                                    End-User fails to comply with any term or condition of the Agreement.
                                    Each End-User agrees upon expiration or termination of this license to
                                    immediately un-install the App.
                                </p>
                                <p>
                                    License/Usage Restrictions. THE APP IS LICENSED TO END-USERS, NOT
                                    SOLD. END-USERS MAY NOT SUBLICENSE, ASSIGN, RESELL, SHARE, PLEDGE,
                                    RENT OR TRANSFER ANY OF THEIR RIGHTS UNDER THE AGREEMENT IN RELATION
                                    TO THE APP OR ANY PORTION THEREOF. EXCEPT AS EXPRESSLY PERMITTED BY
                                    COPYRIGHT LAWS, NO COPYING, REDISTRIBUTION, DISPLAYING, PERFORMING,
                                    REPRODUCING, LICENSING, TRANSFERRING OR PUBLICATION OF THE APP IS
                                    PERMITTED WITHOUT THE EXPRESS PERMISSION OF SHOWFER MEDIA, WHICH
                                    CONSENT SHALL BE AT SHOWFER MEDIA’S SOLE AND ABSOLUTE DISCRETION. ANY
                                    SUCH COPY THAT IS MADE IS SUBJECT TO THE PROVISIONS OF THE AGREEMENT,
                                    AND ALL TITLES, TRADEMARKS, COPYRIGHT NOTICES AND OTHER LEGENDS SHALL
                                    BE REPRODUCED ON SUCH COPY. END-USERS MAY NOT MODIFY, TRANSLATE,
                                    REVERSE ENGINEER, DECOMPILE, DISASSEMBLE OR CREATE DERIVATIVE WORKS OF
                                    THE APP OR OTHERWISE ATTEMPT TO: (i) DEFEAT, AVOID, BY-PASS, REMOVE,
                                    DEACTIVATE OR OTHERWISE CIRCUMVENT ANY PROTECTION MECHANISMS
                                    ASSOCIATED WITH THE APP INCLUDING, WITHOUT LIMITATION, ANY SUCH
                                    MECHANISM USED TO RESTRICT OR CONTROL THE FUNCTIONALITY OF THE APP; OR
                                    (ii) DERIVE THE SOURCE CODE OR THE UNDERLYING IDEAS, ALGORITHMS,
                                    STRUCTURE OR ORGANIZATION FORM OF THE APP. END-USERS SHALL NOT USE THE
                                    APP TO DEVELOP ANY PRODUCT AND/OR SERVICE THAT HAS THE SAME OR ANY
                                    SIMILAR FUNCTION AS THE APP OR OTHER SHOWFER MEDIA OFFERINGS.
                                </p>
                                <p>
                                    Export Restrictions. Each End-User agrees that the App may not be
                                    transferred or exported into any other country, or used in any manner
                                    prohibited by U.S. or other applicable export laws and/or regulations.
                                </p>
                            </li>
                            <li>
                                <h4>YouTube Products</h4>
                                <p>
                                    Showfer Media may, in its sole discretion, offer
                                    End-Users to access/use YouTube products through YouTube API Services.
                                    Each End-User agrees to use the Showfer Media Offerings in accordance
                                    with YouTube's Terms of Service <a
                                    href="https://www.youtube.com/t/terms">https://www.youtube.com/t/terms</a>.
                                </p>
                            </li>
                            <li>
                                <h4>General Disclaimers</h4>
                                <p>
                                    ALL DETERMINATIONS OF SHOWFER MEDIA WITH
                                    RESPECT TO THE SHOWFER MEDIA OFFERINGS WILL BE MADE IN SHOWFER MEDIA’S
                                    SOLE AND ABSOLUTE DISCRETION, AND WILL BE FINAL AND BINDING ON YOU.
                                    YOU UNDERSTAND AND AGREE THAT SHOWFER MEDIA IS NOT RESPONSIBLE OR
                                    LIABLE IN ANY MANNER WHATSOEVER FOR YOUR INABILITY TO ACCESS/USE
                                    SHOWFER MEDIA OFFERINGS. SHOWFER MEDIA IS NOT RESPONSIBLE FOR
                                    ATTEMPTED ACCESS/USE THAT ARE LOST, LATE, ILLEGIBLE, MISDIRECTED,
                                    DAMAGED, INCOMPLETE OR INCORRECT, OR FOR YOUR FAILURE, AT ANY TIME, TO
                                    THE SHOWFER MEDIA OFFERINGS.
                                </p>
                            </li>
                            <li>
                                <h4>Showfer Media Content</h4>
                                <p>
                                    Subject to the terms and conditions of the
                                    Agreement, End-Users that possess the requisite technology shall have
                                    the opportunity to view, download and/or interact with all or some of
                                    the Showfer Media Content made available by and through the Service
                                    and/or other Showfer Media Offerings. The Showfer Media Content is
                                    compiled, distributed and displayed by Showfer Media, as well as
                                    third-party content providers, such as advertisers (collectively,
                                    “Third-Party Providers”). Showfer Media does not control the Showfer
                                    Media Content provided by Third-Party Providers that is made available
                                    by and through the Showfer Media Offerings. Such Third-Party Providers
                                    are solely responsible for the accuracy, completeness,
                                    appropriateness, safety and/or usefulness of such Showfer Media
                                    Content. The Showfer Media Content should not necessarily be relied
                                    upon. Showfer Media does not represent or warrant that the Showfer
                                    Media Content and other information posted by and/or through the
                                    Showfer Media Offerings is accurate, complete, up-to-date or
                                    appropriate. End-Users understand and agree that Showfer Media will
                                    not be responsible for, and Showfer Media undertakes no responsibility
                                    to monitor or otherwise police, Showfer Media Content provided by
                                    Third-Party Providers. End-Users agree that Showfer Media shall have
                                    no obligation and incur no liability to such End-Users in connection
                                    with any Showfer Media Content. End-Users may find certain Showfer
                                    Media Content to be outdated, harmful, inaccurate and/or deceptive.
                                    Please use caution, common sense and safety when using the Showfer
                                    Media Content. A copyright owner or an agent authorized to act on the
                                    owner’s behalf may submit a copyright complaint
                                    via <a
                                    href="https://www.youtube.com/copyright_complaint_form">https://www.youtube.com</a> in
                                    case any copyright-protected work was
                                    posted in any Showfer Media Content from YouTube products made
                                    available by and through the Service and/or other Showfer Media
                                    Offerings.
                                </p>
                            </li>
                            <li>
                                <h4>Your Content</h4>
                                <p>
                                    Some of the Service allow you to play video or audio
                                    files stored in your Mobile Device, Google Drive™ or iTunes®. You may
                                    play those video or audio files only for your individual use. We do
                                    not allow you to upload or share any video or audio file through the
                                    Service.
                                </p>
                                <p>
                                    Some of the Service allow you to upload, submit, store or send certain
                                    text or images relating to the Showfer Media Offerings (collectively,
                                    “Your Content”), including user comments. You understand that we does
                                    not guarantee any confidentiality with respect to Your Content.
                                </p>
                                <p>
                                    You shall be solely responsible for Your Content and the consequences
                                    of submitting and publishing Your Content on the Showfer Media
                                    Offerings. You affirm, represent, and warrant that you own or have the
                                    necessary licenses, rights, consents, and permissions to publish Your
                                    Content; and you license to us all patent, trademark, trade secret,
                                    copyright or other proprietary rights in and to such Your Content for
                                    publication on the Showfer Media Offerings.
                                </p>
                                <p>
                                    By submitting Your Content to the Showfer Media Offerings, you hereby
                                    grant us a worldwide, non-exclusive, royalty-free, sublicenseable and
                                    transferable license to use, reproduce, distribute, prepare derivative
                                    works of, display, and perform Your Content in connection with the
                                    Showfer Media Offerings, including without limitation for promoting
                                    and redistributing part or all of the Service (and derivative works
                                    thereof) in any media formats and through any media channels. You also
                                    hereby grant each user of the Showfer Media Offerings a non-exclusive
                                    license to access Your Content through the Showfer Media Offerings,
                                    and to use, reproduce, distribute, display and perform such Your
                                    Content as permitted through the functionality of the Showfer Media
                                    Offerings and under this EULA. The above licenses granted by you in
                                    user comments you submit are perpetual and irrevocable.
                                </p>
                                <p>
                                    You further agree that Your Content will not contain third party
                                    copyrighted material, or material that is subject to other third party
                                    proprietary rights, unless you have permission from the rightful owner
                                    of the material or you are otherwise legally entitled to post the
                                    material and to grant us all of the license rights granted herein.
                                </p>
                                <p>
                                    You further agree that you will not submit any Your Content or other
                                    material that is contrary to the YouTube Community Guidelines,
                                    currently found
                                    at <a href="https://support.google.com/youtube/answer/9288567?hl=en">youtube
                                    support</a> which
                                    may be updated from time to time, or
                                    contrary to applicable local, national, and international laws and
                                    regulations. We reserve the right in our sole discretion to remove any of Your
                                    Content without prior notice.
                                </p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <h3>Representations and Warranties</h3>
                        <p>
                            Each End-User hereby represents and
                            warrants to Showfer Media as follows:
                        </p>
                        <ul className="terms-list-letters">
                            <li>
                                <p>
                                    the Agreement constitutes
                                    such End-User’s legal, valid and binding obligation which is fully
                                    enforceable against such End-User in accordance with its terms;
                                </p>
                            </li>
                            <li>
                                <p>
                                    such End-User understands and agrees that such End-User has
                                    independently evaluated the desirability of utilizing the Showfer
                                    Media Offerings and that such End-User has not relied on any
                                    representation and/or warranty other than those set forth in the
                                    Agreement
                                </p>
                            </li>
                            <li>
                                <p>
                                    such End-User’s performance under the Agreement and
                                    such End-User’s use of the Showfer Media Offerings will not: (i)
                                    violate any Applicable Law; and/or (ii) otherwise infringe upon the
                                    rights of any third parties including, without limitation, those of
                                    copyright, patent, trademark, trade secret or other intellectual
                                    property right, false advertising, unfair competition, defamation,
                                    invasion of rights of celebrity, violation of any anti-discriminatory
                                    law or regulation, or any other right of any person or entity.
                                </p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <h3>Indemnification</h3>
                        <p>
                            Each End-User agrees to indemnify, defend and hold
                            Showfer Media, its parents, affiliates and/or subsidiaries, and each
                            of their respective officers, partners, contractors, members,
                            managers, employees, agents and attorneys, harmless from and against
                            any and all liabilities, claims, actions, suits, proceedings,
                            judgments, fines, damages, costs, losses and expenses (including
                            reasonable attorneys’ fees, court costs and/or settlement costs)
                            arising from or related to:
                        </p>
                        <ul className="terms-list-letters">
                            <li>
                                <p>
                                    End-User’s breach of the Agreement
                                    and/or any representation or warranty contained herein
                                </p>

                            </li>
                            <li>
                                <p>
                                    any allegation that End-User has infringed upon the trademark, trade name,
                                    service mark, copyright, license, intellectual property or other
                                    proprietary right of any third-party
                                </p>
                            </li>
                            <li>
                                <p>
                                    End-User’s
                                    unauthorized and/or improper use of the Showfer Media Offerings
                                </p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <h3>License Grant</h3>
                        <p>
                            Each End-User is granted a non-exclusive,
                            non-transferable, revocable and limited license to access and use the
                            Showfer Media Offerings. Showfer Media may terminate this license at
                            any time for any reason. Unless otherwise expressly authorized by
                            Showfer Media in writing in each instance, End-Users may only use the
                            Showfer Media Offerings for their own personal, non-commercial use. No
                            part of the Showfer Media Offerings may be reproduced in any form or
                            incorporated into any information retrieval system, electronic or
                            mechanical. No End-User or other third-party may use any automated
                            means or form of scraping or data extraction to access, query or
                            otherwise collect material from the Showfer Media Offerings except as
                            expressly permitted by Showfer Media. No End-User or other third-party
                            may use, copy, emulate, clone, rent, lease, sell, modify, decompile,
                            disassemble, reverse engineer or transfer the Showfer Media Offerings,
                            or any portion thereof. No End-User or other third-party may create
                            any “derivative works” by altering any aspect of the Showfer Media
                            Offerings. No End-User or other third-party may use the Showfer Media
                            Offerings in conjunction with any other third-party content. No
                            End-User or other third-party may exploit any aspect of the Showfer
                            Media Offerings for any commercial purposes not expressly permitted by
                            Showfer Media. Each End-User further agrees to indemnify and hold
                            Showfer Media harmless for that End-User’s failure to comply with this
                            Section 8. Showfer Media reserves any rights not explicitly granted in
                            the Agreement.
                        </p>
                    </li>
                    <li>
                        <p>
                            Proprietary Rights. The Showfer Media Offerings, as well as the
                            organization, graphics, design, compilation, magnetic translation,
                            digital conversion, software, services and other matters related to
                            same, are protected under applicable copyrights, trademarks and other
                            proprietary (including, but not limited to, intellectual property)
                            rights. The copying, redistribution or publication by any End-User or
                            other third-party of any part of the Showfer Media Offerings is
                            strictly prohibited. No End-User or other third-party acquires
                            ownership rights in or to any content, document, software, services or
                            other materials viewed by or through the Showfer Media Offerings. The
                            posting of information or material by and through the Showfer Media
                            Offerings does not constitute a waiver of any right in or to such
                            information and/or materials. The “Airy TV” “WooHoo TV” “Showfer
                            Media” name and logo are trademarks of Showfer Media. All other
                            trademarks, including those associated with the YouTube products, are
                            the property of their respective owners. The use of any trademark
                            without the applicable trademark owner\'s express written consent is
                            strictly prohibited.
                        </p>
                    </li>
                    <li>
                        <p>
                            Legal Warning. Any attempt by any individual to damage, destroy,
                            tamper with, vandalize and/or otherwise interfere with the operation
                            of the Showfer Media Offerings is a violation of criminal and civil
                            law and Showfer Media will diligently pursue any and all remedies in
                            this regard against any offending individual or entity to the fullest
                            extent permissible by law and in equity.
                        </p>
                    </li>
                    <li>
                        <p>
                            Disclaimer of Warranties. THE SHOWFER MEDIA OFFERINGS AND/OR ANY
                            OTHER PRODUCTS AND/OR SERVICES OFFERED BY AND/OR THROUGH SAME ARE
                            PROVIDED TO END-USERS ON AN “AS IS” AND “AS AVAILABLE” BASIS AND ALL
                            WARRANTIES, EXPRESS AND IMPLIED, ARE DISCLAIMED TO THE FULLEST EXTENT
                            PERMISSIBLE PURSUANT TO APPLICABLE LAW (INCLUDING, BUT NOT LIMITED TO,
                            THE DISCLAIMER OF ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT
                            OF INTELLECTUAL PROPERTY AND/OR FITNESS FOR A PARTICULAR PURPOSE). IN
                            PARTICULAR, BUT NOT AS A LIMITATION THEREOF, SHOWFER MEDIA MAKES NO
                            WARRANTY THAT THE SHOWFER MEDIA OFFERINGS AND/OR ANY OTHER PRODUCTS
                            AND/OR SERVICES OFFERED BY AND/OR THROUGH SAME: (A) WILL MEET ANY
                            END-USER’S REQUIREMENTS; (B) WILL BE UNINTERRUPTED, TIMELY, SECURE OR
                            ERROR-FREE OR THAT DEFECTS WILL BE CORRECTED; (C) WILL BE FREE OF
                            HARMFUL COMPONENTS; (D) WILL RESULT IN ANY ECONOMIC BENEFIT; AND/OR
                            (E) WILL BE ACCURATE OR RELIABLE. THE SHOWFER MEDIA OFFERINGS AND/OR
                            ANY OTHER PRODUCTS AND/OR SERVICES OFFERED BY AND/OR THROUGH SAME MAY
                            CONTAIN BUGS, ERRORS, PROBLEMS OR OTHER LIMITATIONS. SHOWFER MEDIA
                            WILL NOT BE LIABLE FOR THE AVAILABILITY OF THE UNDERLYING INTERNET
                            AND/OR MOBILE CONNECTION ASSOCIATED WITH THE SHOWFER MEDIA OFFERINGS.
                            NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY ANY
                            END-USER FROM SHOWFER MEDIA OR OTHERWISE THROUGH OR FROM THE SHOWFER
                            MEDIA OFFERINGS SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE
                            AGREEMENT.
                        </p>
                    </li>
                    <li>
                        <h3>Limitation of Liability</h3>
                        <p>
                            EACH END-USER EXPRESSLY UNDERSTANDS AND
                            AGREES THAT SHOWFER MEDIA SHALL NOT BE LIABLE TO THAT END-USER OR ANY
                            THIRD-PARTY FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
                            CONSEQUENTIAL AND/OR EXEMPLARY DAMAGES INCLUDING, BUT NOT LIMITED TO,
                            DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE
                            LOSSES (EVEN IF SHOWFER MEDIA HAS BEEN ADVISED OF THE POSSIBILITY OF
                            SUCH DAMAGES), TO THE FULLEST EXTENT PERMISSIBLE BY LAW FOR: (A) THE
                            USE OR INABILITY TO USE THE APP, THE SHOWFER MEDIA OFFERINGS AND/OR
                            ANY OTHER PRODUCTS AND/OR SERVICES OFFERED BY AND/OR THROUGH SAME; (B)
                            THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING
                            FROM ANY GOODS, DATA, INFORMATION, CONTENT AND/OR ANY OTHER PRODUCTS
                            PURCHASED OR OBTAINED FROM OR THROUGH THE SHOWFER MEDIA OFFERINGS; (C)
                            ANY DISPUTE BETWEEN ANY END-USERS AND/OR THIRD PARTIES; (D) ANY MATTER
                            RELATING TO ANY END-USER CONTENT, PERKS AND/OR CAMPAIGNS; (E) THE
                            UNAUTHORIZED ACCESS TO, OR ALTERATION OF, ANY END-USER’S REGISTRATION
                            DATA; (F) END-USER’S FAILURE TO REALIZE ANY ECONOMIC BENEFIT; AND (G)
                            ANY OTHER MATTER RELATING TO THE SHOWFER MEDIA OFFERINGS AND/OR ANY
                            OTHER PRODUCTS AND/OR SERVICES OFFERED BY AND/OR THROUGH SAME. THIS
                            LIMITATION APPLIES TO ALL CAUSES OF ACTION, IN THE AGGREGATE
                            INCLUDING, BUT NOT LIMITED TO, BREACH OF CONTRACT, BREACH OF WARRANTY,
                            NEGLIGENCE, STRICT LIABILITY, MISREPRESENTATION AND ANY AND ALL OTHER
                            TORTS. EACH END-USER HEREBY RELEASES SHOWFER MEDIA FROM ANY AND ALL
                            OBLIGATIONS, LIABILITIES AND CLAIMS IN EXCESS OF THE LIMITATIONS
                            STATED HEREIN. NO ACTION, REGARDLESS OF FORM, ARISING OUT OF THE
                            SHOWFER MEDIA OFFERINGS AND/OR ANY OTHER PRODUCTS AND/OR SERVICES
                            OFFERED BY AND/OR THROUGH SAME, MAY BE BROUGHT BY ANY END-USER OR
                            SHOWFER MEDIA MORE THAN ONE (1) YEAR FOLLOWING THE EVENT WHICH GAVE
                            RISE TO THE CAUSE OF ACTION. THE NEGATION OF DAMAGES SET FORTH ABOVE
                            IS A FUNDAMENTAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN EACH
                            END-USER AND SHOWFER MEDIA. ACCESS TO THE SHOWFER MEDIA OFFERINGS
                            WOULD NOT BE PROVIDED TO ANY END-USERS WITHOUT SUCH LIMITATIONS. SOME
                            JURISDICTIONS DO NOT ALLOW CERTAIN LIMITATIONS ON LIABILITY AND IN
                            SUCH JURISDICTIONS THE LIABILITY OF SHOWFER MEDIA SHALL BE LIMITED TO
                            THE MAXIMUM EXTENT PERMITTED BY LAW.
                        </p>
                    </li>
                    <li>
                        <h3>Third-party Websites</h3>
                        <p>
                            The Showfer Media Offerings contain links to
                            other websites on the Internet that are owned and operated by third
                            parties including, without limitation, the Download Venues. Showfer
                            Media does not control the information, products or services available
                            on or through these third-party websites. The inclusion of any link
                            does not imply endorsement by Showfer Media of the applicable website
                            or any association with the website’s operators. Because Showfer Media
                            has no control over such websites or resources, each End-User agrees
                            that Showfer Media is not responsible or liable for the availability
                            or the operation of such external websites, for any material located
                            on or available from or through any such websites or for the
                            protection of any End-User’s data privacy by third parties. Each
                            End-User further agrees that Showfer Media shall not be responsible or
                            liable, directly or indirectly, for any loss or damage caused by the
                            use of or reliance on any such material available on or through any
                            such site or any such dealings.
                        </p>
                    </li>
                    <li>
                        <h3>Editing, Deleting and Modification</h3>
                        <p>
                            We reserve the right in our
                            sole discretion to edit and/or delete any documents, information or
                            other content appearing by and/or through the Service and/or other
                            Showfer Media Offerings.
                        </p>
                    </li>
                    <li>
                        <h3>Registration Data and other End-User Information</h3>
                        <p>
                            All material
                            submitted by End-Users through or in association with the Showfer
                            Media Offerings including, without limitation, the Registration Data,
                            shall be subject to the Privacy Policy, as shown below.
                        </p>
                    </li>
                    <li>
                        <h3>Dispute Resolution Provisions</h3>
                        <p>
                            The Agreement shall be treated as
                            though it were executed and performed in Palo Alto, California and
                            shall be governed by and construed in accordance with the laws of the
                            State of California (without regard to conflict of law principles).
                            Should a dispute arise concerning the Showfer Media Offerings, the
                            terms and conditions of the Agreement or the breach of same by any
                            party hereto: (a) the parties agree to submit their dispute for
                            resolution by arbitration before a reputable arbitration organization
                            as mutually agreed upon by the parties; and (b) each End-User agrees
                            to first commence a formal dispute proceeding by completing and
                            submitting an Initial Dispute Notice which can be found Here. We may
                            choose to provide petitioning End-Users with a final written
                            settlement offer after receiving such End-User’s Initial Dispute
                            Notice (“Final Settlement Offer”). If we provide a petitioning
                            End-User with a Final Settlement Offer and that End-User does not
                            accept it, or we cannot otherwise satisfactorily resolve that
                            End-User’s dispute and that End-User wishes to proceed, that End-User
                            must submit its dispute for resolution by arbitration before a
                            reputable arbitration organization as mutually agreed upon by the
                            parties, in that End-User’s county of residence, by filing a separate
                            Demand for Arbitration, which is available Here. If the arbitrator
                            awards an End-User relief that is greater than our Final Settlement
                            Offer, then we will pay all filing, administration and arbitrator fees
                            associated with the arbitration and, if that End-User retained an
                            attorney to represent it in connection with the arbitration, we will
                            reimburse any reasonable attorneys’ fees that such End-User’s attorney
                            accrued for investigating, preparing and pursuing the claim in
                            arbitration. Any award rendered shall be final and conclusive to the
                            parties and a judgment thereon may be entered in any court of
                            competent jurisdiction. Nothing contained herein shall be construed to
                            preclude any party from: (i) seeking injunctive relief in order to
                            protect its rights pending an outcome in arbitration; and/or (ii)
                            pursuing the matter in small claims court rather than arbitration.
                            Although we may have a right to an award of attorneys’ fees and
                            expenses if we prevail in arbitration, we will not seek such an award
                            from any End-User unless the arbitrator determines that such
                            End-User’s claim was frivolous.
                        </p>
                        <p>
                            To the extent permitted by law, each End-User agrees that it will not
                            bring, join or participate in any class action lawsuit as to any
                            claim, dispute or controversy that it may have against Showfer Media
                            and/or its employees, officers, directors, members, representatives
                            and/or assigns. Each End-User agrees to the entry of injunctive relief
                            to stop such a lawsuit or to remove it as a participant in the suit.
                            Each End-User agrees to pay the attorney’s fees and court costs that
                            Showfer Media incurs in seeking such relief. This provision preventing
                            End-Users from bringing, joining or participating in class action
                            lawsuits: (A) does not constitute a waiver of any End-User’s rights or
                            remedies to pursue a claim individually and not as a class action in
                            binding arbitration as provided above; and (B) is an independent
                            agreement. Any End-User may opt-out of these dispute resolution
                            provisions by providing written notice of her/his decision within
                            thirty (30) days of the date that it first accesses the Service.
                        </p>
                    </li>
                    <li>
                        <h3>Miscellaneous</h3>
                        <p>
                            To the extent that anything in or associated with
                            the Showfer Media Offerings is in conflict or inconsistent with the
                            Agreement, the Agreement shall take precedence. Showfer Media’s
                            failure to enforce any provision of the Agreement shall not be deemed
                            a waiver of such provision nor of the right to enforce such provision.
                            The parties do not intend that any agency or partnership relationship
                            be created through operation of the Agreement. Should any part of the
                            Agreement be held invalid or unenforceable, that portion shall be
                            construed consistent with applicable law and the remaining portions
                            shall remain in full force and effect. Showfer Media may assign its
                            rights and obligations under the Agreement, in whole or in part, to
                            any party at any time without notice to you. The Agreement may not,
                            however, be assigned by you, and you may not delegate your duties
                            under it. Headings are for reference purposes only and in no way
                            define, limit, construe or describe the scope or extent of such
                            section.
                        </p>
                    </li>
                    <li>
                        <h3>Copyright information</h3>
                        <p>
                            This NOT an official app from the various
                            online media services. This is only an unofficial 3rd-party client
                            that complies with their 3rd party API terms of service, and is NOT an
                            affiliated nor related product of those services. Per their API
                            developer terms: <a
                            href="https://developers.google.com/youtube/branding_guidelines">youtube</a> You
                            do not need special approval to use
                            YouTube APIs or to promote API functionality in your application"
                            provided that the guidelines are followed.
                        </p>
                        <p>
                            All YouTube videos are provided by the public third-party media
                            service YouTube, and all trademarks and copyrights belong to their
                            respective owners.
                        </p>
                        <p>
                            Some television programs (e.g. – WooHoo TV, Airy TV) are hosted on our
                            own streaming platform (non-YouTube platform) under permission from
                            the respective publishers. All external links to videos are public
                            videos on various content creators’ official websites. If you have any
                            further questions, please feel free to contact us at <a
                            href="mailto:support@airy.tv">support@airy.tv</a>
                        </p>
                    </li>
                    <li>
                        <h3>Contact Us</h3>
                        <p>
                            If any End-User has any questions about the Agreement,
                            Showfer Media Offerings or the practices of Showfer Media, that
                            End-User can email us as at: <a href="mailto:support@airy.tv">support@airy.tv</a>
                        </p>
                    </li>
                </ol>
            </section>
        </div>
    );
}

export default TermsOfUse;